<template>
  <b-container class="b-card-forms">
    <b-row class="vh-100" align-h="center">
      <b-col align-self="center" cols="12" sm="9" lg="5">
        <b-card class="text-center error">
          <img class="webmag-logo-big" src="@/assets/logo-webmag-big.png" alt="webmag logo"/>
          <h3>{{ $t('somethingWrong.title') }}</h3>
          <b-alert
            v-if="errorMsg"
            show
            variant="danger"
            v-html="errorMsg"
          ></b-alert>
          <p class="mb-2" v-html="$t('somethingWrong.desc')"></p>
          <router-link class="d-block mt-4" to="/">{{ $t('buttons.toDashboard') }}</router-link>
          <div v-if="codeBlock" class="mt-5">
            <hr>
            <div class="d-block text-left mt-4 error-msg-block">
              <h4>Error Message:</h4>
              <div v-if="debugData" class="mb-2" v-html="debugData"></div>
              <div v-html="codeBlock"></div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Auth from '@aws-amplify/auth';
import SetLanguageToBrowserLanguage from '@/mixins/setLanguageToBrowserLanguage';

export default {
  name: 'SomeThingWentWrong',
  mixins: [SetLanguageToBrowserLanguage],
  data() {
    return {
      problem: null,
      debugData: null,
      codeBlock: null,
    };
  },
  computed: {
    errorMsg() {
      switch (this.problem) {
        case 'emailNotConfirmed':
          return this.$t('somethingWrong.errorEmailConfirmed');
        case 'accountSetupProblem':
          return this.$t('somethingWrong.errorAccountSetup');
        default:
          console.log(this.problem);
          return null;
      }
    },
  },
  async created() {
    try {
      const userInfo = await Auth.currentUserInfo();
      if (!userInfo) {
        this.setLanguageToBrowserLanguage();
      }
    } catch (error) {
      this.setLanguageToBrowserLanguage();
    }
    if (this.$route.query?.error) {
      this.problem = this.$route.query.error;
    }
    if (this.$route.query?.debugData) {
      this.debugData = this.$route.query.debugData;
    }
    if (this.$route.query?.errorData) {
      this.codeBlock = JSON.parse(this.$route.query.errorData);
    }
  },
};
</script>

<style scoped>
  h4 {
    font-size: 13px;
    font-weight: bold;
  }
  .error-msg-block {
    font-size: 12px;
    line-height: 15px;
    color: #000;
  }
</style>
